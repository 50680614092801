import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpRequest
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { environment } from '@env/environment';
import { COOKIE_D_Id_PROP_NAME } from '@utils/storage.utils';
import { HttpStatusEnum } from '@models/http.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const authService = inject(AuthService);
  const authToken = authService.getToken();
  const disallowedRoutes = [`${environment.apiUrl}/account/token`];
  const isAllowedReqUrl = !disallowedRoutes.includes(req.url);

  const deviceHash = authService.getDeviceHash();
  if (deviceHash) {
    req = req.clone({
      setHeaders: {
        [COOKIE_D_Id_PROP_NAME]: deviceHash
      }
    });
  }

  if (authToken && isAllowedReqUrl) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });
  }

  return next(req).pipe(
    catchError((errResponse: HttpErrorResponse) => {
      if (
        [HttpStatusEnum.Unauthorized, HttpStatusEnum.Forbidden].includes(
          errResponse.status
        ) &&
        !disallowedRoutes.includes(req.url)
      ) {
        authService.logout(true);
      }

      return throwError(() => errResponse);
    })
  );
}
