import { Routes } from '@angular/router';
import { coreShellRoutes } from '@app/core/core-shell.routes';
import { RoutePaths } from '@shared/utils/route.utils';

export const routes: Routes = [
  {
    path: RoutePaths.Inject.path,
    loadChildren: () =>
      import('src/app/pages/impersonation/impersonation-shell.routes').then(
        (r) => r.impersonationShellRoutes
      )
  },
  {
    path: '',
    loadComponent: () =>
      import('./core/core.component').then((c) => c.CoreComponent),
    children: [...coreShellRoutes]
  },
  { path: '**', redirectTo: RoutePaths.Core.Home.absolutePath }
];
